.widget_users {
	cursor: default;
}

.superadmin_edit-user {
	.edit-user_warning {
		color: red;
		font-weight: 500;
	}
}
