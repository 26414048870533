.widget_active-training {
	.widget_header {
		background-color: var(--color-yellow);
		color: var(--color-grey-very-dark);
		font-weight: 500;
	}

	.active-training_content {
		display: grid;
		grid-template-columns: 1fr 1fr;
	}

	.active-training_players {
		display: flex;
		flex-direction: column;
		max-height: 70vh;
		@include scrollbar;
	}

	.active-players_overview {
		display: flex;
		flex-direction: column;
		// border-left: 1px solid var(--color-grey-very-light);
		border-bottom: 1px solid var(--color-grey-very-light);
		border-right: 1px solid var(--color-grey-very-light);
	}

	.active-players_table {
		.active-players_header,
		.active-players_player-container {
			display: grid;
			grid-template-columns: 3rem 1fr 8rem 8rem 8rem;
			padding: 0.2rem 0 0.2rem 1rem;
		}

		.active-players_header {
			font-weight: 600;
			padding: 0.5rem 0 0.5rem 1rem;
		}

		.active-players_header--score,
		.active-players_header--percent,
		.active-player_score,
		.active-player_percent {
			justify-self: center;
		}

		.active-player_button {
			.btn {
				padding: 0.3rem 2rem;
				font-size: 1.4rem;
				min-width: 7rem;
				margin: 0 0.25rem;
			}
		}
	}

	.active-training_score-build {
		align-self: center;
		margin-top: 1rem;

		.btn--score-build {
			color: var(--color-primary);
			font-weight: 500;
			box-shadow: 0 0 0 2px var(--color-grey-medium);
			border-radius: 5rem;
			width: 18rem;
			padding: 0.3rem 0;
			display: flex;
			column-gap: 0.5rem;
			align-items: center;
			justify-content: center;
			margin-bottom: 1rem;

			.icon {
				width: 2rem;
				fill: var(--color-primary);
				stroke: var(--color-primary);
			}
		}
	}

	.active-training_url {
		display: flex;

		.btn--copy-url {
			display: flex;
			column-gap: 1rem;
			width: 80%;
			border: 1px solid var(--color-grey-very-light);
			padding: 0.5rem 1rem;
			margin: 1rem 0.5rem 1rem 1rem;
			border-radius: 5rem;
			box-shadow: 0 0 0 1px var(--color-grey-light);

			.icon {
				fill: var(--color-primary);
				stroke: var(--color-primary);
			}

			.form_label {
				color: var(--color-primary);
				padding-top: 0.2rem;
			}
		}

		.btn--view-url {
			.icon {
				fill: var(--color-grey-light);
				stroke: var(--color-grey-light);
			}
		}
	}

	.active-training_notes {
		margin: 0.5rem 0 0 1rem;
		display: flex;
		flex-direction: column;

		.active-training_notes-field {
			@include scrollbar;
			height: 10rem;
			border: 1px solid var(--color-grey-very-light);
			padding: 0.2rem;
		}
	}

	.active-training_finish-training {
		justify-self: center;
		align-self: center;

		.btn {
			background-color: red;
			color: white;
			padding: 0.3rem 0;
			width: 14rem;
			margin: 1rem 0;
		}
	}

	.active-training_dashboard-container {
		display: flex;
		flex-direction: column;
		row-gap: 0.3rem;
		width: 100%;

		.active-training_dashboard {
			display: flex;

			.active-training_dashboard-live {
				background-color: var(--color-grey-very-dark);
				width: 100%;
				aspect-ratio: 16 / 9;
				margin: 1rem 0 0 1rem;
				display: flex;
			}

			.icon-container {
				align-items: flex-end;
			}
			.btn--tab {
				width: 4rem;
				height: 4rem;
				align-self: flex-end;

				.icon {
					height: 2.6rem;
					width: 2.6rem;
					fill: var(--color-grey-dark);
					stroke: var(--color-grey-dark);
					stroke-width: 0;
				}
			}
		}

		.active-training_dashboard-controls {
			width: 91.2%;
			margin-left: 1rem;
			height: 11rem;
			background-color: var(--color-grey-very-dark);
			position: relative;
			box-shadow: 0 2px 5px rgba(0, 0, 0, 0.05);

			.dashboard-controls_rounds {
				padding: 0.5rem 0.5rem;
				display: grid;
				grid-template-columns: auto 1fr auto;
				align-items: center;
				color: white;

				.icon {
					fill: white;
					stroke: white;
					height: 2.2rem;
					width: 2.2rem;
					stroke-width: 0;
				}
				.dashboard-controls_left {
					transform: rotate(180deg);
				}

				.dashboard-controls_header {
					display: flex;
					flex-direction: column;
					align-items: center;
					justify-content: center;
					align-self: center;
					justify-self: center;

					.dashboard-controls_name {
						font-weight: 500;
					}

					.dashboard-controls_time {
						display: flex;
						align-items: center;
						column-gap: 0.5rem;
						font-weight: 600;
					}
				}
			}

			.dashboard-controls_button {
				display: flex;
				align-items: center;
				justify-content: center;
				margin-top: 0.5rem;

				.btn {
					width: 15rem;
				}

				.btn--orange {
					box-shadow: 0 0 3px rgba(0, 0, 0, 0.05);
				}

				.btn--green {
					&:active {
						filter: none;
					}

					&:hover {
						cursor: default;
						filter: none;
					}
				}
			}

			.dashboard-controls_finish {
				color: var(--color-grey-very-light);
				text-align: end;
				padding-right: 1rem;
				margin-top: -1rem;
				text-decoration: underline;
				font-size: 1rem;
				margin-right: 0.2rem;
			}
		}
	}
}

.popup_finish-training {
	&.popup {
		width: 60rem;
	}
	ul li {
		list-style-type: disc;
		margin-left: 3rem;
	}

	p {
		margin-bottom: 0.5rem;
	}
}
