.timelines {
	&_overview {
		display: flex;
		flex-direction: column;
		height: 100%;
		font-size: 1.4rem;
		@include scrollbar;
	}

	&_add-timeline {
		font-size: 1.4rem;
		font-weight: 600;
		color: var(--color-primary);
		text-decoration: underline;
		margin-right: 8rem;
	}

	&_group:last-child {
		height: 100%;
		align-self: top;
	}

	&_group {
		margin-top: 1rem;

		.timeline_header {
			display: flex;
			align-items: center;

			&--name {
				display: flex;

				.icon--dropdown,
				.icon--dropdown-right {
					height: 1.8rem;
					fill: var(--color-grey-light);
					stroke: var(--color-grey-light);
				}

				.icon--dropdown-right {
					transform: rotate(270deg);
				}

				.timeline_name,
				.timeline_time {
					font-weight: 600;
					padding-top: 0.2rem;
					cursor: pointer;
				}

				.timeline_counter {
					margin-left: 3rem;
					display: flex;
					align-items: center;
					column-gap: 0.4rem;

					.edit-name_icon-container {
						align-self: center;
					}

					.icon {
						height: 1.6rem;
					}

					.timeline_counter--name {
						padding-top: 0.2rem;
						font-size: 1.4rem;
					}
				}
			}

			&--edit {
				margin-left: 4rem;
				margin-left: auto;
				margin-right: 4rem;
				display: flex;
				align-items: center;
				column-gap: 0.5rem;

				.icon-container {
					display: inline;
				}

				.icon--pen,
				.icon--delete {
					height: 1.6rem;
					width: 1.6rem;
				}
			}
		}

		.timeline_header--counter {
			margin-left: 5rem;
			display: flex;
			align-items: center;
			column-gap: 0.4rem;

			.icon {
				height: 1.8rem;
			}

			.timeline_counter--name {
				padding-top: 0.5rem;
				font-size: 1.4rem;
			}
		}

		.timeline_header-finished {
			font-weight: 600;
			padding-left: 2rem;
		}

		.table-container {
			padding: 0.5rem 2rem 1rem 2rem;
		}

		.table {
			border: 1px solid var(--color-grey-light);
			table-layout: fixed;
			border-collapse: collapse;

			th {
				border-bottom: 1px solid var(--color-grey-light);
				padding: 0 0.2rem;
			}

			tr {
				border-bottom: 1px solid var(--color-grey-very-light);
			}

			td {
				padding: 0 0.2rem;
			}

			.selected {
				color: var(--color-grey-very-dark);
				background-color: var(--color-grey-very-light);
			}
		}

		.timeline_events {
			&--add {
				display: flex;
				align-items: center;
				justify-content: center;
			}
		}
	}
}

//Story editor
.popup.popup_add-timeline {
	min-width: 50rem;
	min-height: 40rem;

	.timeline_add-counter {
		display: flex;
		margin-top: 2rem;
		column-gap: 1.2rem;
		align-items: flex-end;
	}

	.add-timeline_name {
		.form_field {
			width: 18rem;
		}
	}

	.add-timeline_time {
		.form_field {
			width: 9rem;
		}
	}

	.add-timeline_counter {
		display: flex;
		flex-direction: column;
		align-items: flex-start;
	}
	.icon--counter {
		height: 3.2rem;
	}

	.form_dropdown,
	.form_dropdown-option {
		width: 28rem;
	}
}

.timelines {
	&_add-timeline {
		font-size: 1.4rem;
		font-weight: 600;
		color: var(--color-primary);
		text-decoration: underline;
		margin-right: 8rem;
		cursor: pointer;
		width: fit-content;
	}
}

.table.timelines_story-editor {
	width: 100%;

	.time {
		width: 15%;
	}

	.description {
		width: 77%;
	}

	.edit {
		width: 8%;
	}

	tr {
		height: 2.6rem;

		&.timeline_event--edit {
			border-bottom: 1px solid var(--color-primary);
		}
	}

	td,
	th {
		vertical-align: middle;
	}

	tr td:last-child {
		display: flex;
		align-items: center;
	}

	tr:hover {
		background-color: var(--color-grey-very-light);
		color: var(--color-grey-very-dark);
		cursor: pointer;
	}

	tr:not(:hover) .dropdown-option_delete-button,
	tr:not(:hover) .dropdown-option_edit-button {
		display: none;
	}

	.table_description {
		white-space: pre-wrap;
		height: inherit;
	}

	.table_edit {
		padding-right: 2rem;
		column-gap: 0.6rem;
		margin-top: 0.4rem;

		.icon {
			height: 1.6rem;
		}
	}
}

.timeline_add,
.timeline_edit {
	display: flex;
	column-gap: 0.8rem;

	.icon--time {
		height: 3.4rem;
		width: 3.4rem;
	}

	.form_text-field {
		width: 16rem;
	}

	.form_time-field,
	.form_timeWithSeconds-field {
		width: 8rem;
	}
}

.timeline_counter {
	.edit-name_icon-container:hover {
		cursor: auto;

		.icon:hover {
			cursor: auto;
			fill: var(--color-grey-medium);
			stroke: var(--color-grey-medium);
		}
	}
}

.add-timeline_counter {
	display: flex;
	align-items: center;
	column-gap: 0.2rem;

	.edit-name_icon-container {
		align-self: center;
	}

	.form_text-field,
	.form_dropdown,
	.form_field {
		width: 16rem;
	}

	.icon {
		height: 1.8rem;
	}
}

.edit-timeline-event_name {
	width: 100%;
}
.edit-timeline-event_buttons {
	display: flex;
	justify-content: flex-end;
}

//game editor
.popup.popup_view-timelines {
	min-width: 70vw;
	max-width: 80vw;
	min-height: 60vh;
	max-height: 85vh;
	row-gap: 0;
	grid-template-rows: auto 1fr;
	padding-bottom: 1rem;

	.timeline_header--name {
		column-gap: 1rem;
	}

	.timeline_header-finished {
		padding-top: 1rem;
	}
}

.popup_timeline-actions {
	.form_dropdown {
		width: 28rem;

		.form_field {
			column-gap: 1rem;
		}
	}
}

.table.timelines_game-editor {
	width: 98%;

	.time {
		width: 10%;
	}

	.description {
		width: 40%;
	}

	.action {
		width: 50%;
	}

	.table_time,
	.table_description,
	.table_action {
		vertical-align: top;
	}
	.table_action {
		&-container {
			display: grid;
			grid-template-columns: auto 1fr auto;
			column-gap: 1rem;
			align-items: top;

			&:hover {
				background-color: var(--color-grey-very-light);
				color: var(--color-grey-very-dark);
				cursor: pointer;
			}

			&:not(:hover) .dropdown-option_add-button,
			&:not(:hover) .dropdown-option_delete-button,
			&:not(:hover) .dropdown-option_edit-button {
				display: none;
			}

			.action-icon {
				.icon {
					height: 1.8rem;
				}
			}

			.action_item {
				padding-top: 0.2rem;
			}

			.action_edit {
				display: flex;
				column-gap: 1.1rem;
				margin-top: 0.2rem;
				align-items: center;

				.icon-container {
					align-items: start;
				}

				.icon {
					height: 1.6rem;
				}
			}
		}
	}

	.timeline_add-action {
		color: var(--color-primary);
		text-decoration: underline;
		cursor: pointer;
	}

	&_edit {
		margin-top: 0.4rem;
	}
}
tr {
	border: none;
}
