.item-bar {
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: space-evenly;

	button:disabled .icon {
		fill: var(--color-yellow);
		stroke: var(--color-yellow);
		cursor: default;
	}

	.icon {
		height: 3rem;
		fill: white;
		stroke: white;
		stroke-width: 1px;
		filter: drop-shadow(3px 5px 2px rgb(0 0 0 / 0.3));

		&:hover {
			fill: var(--color-item-select);
			stroke: var(--color-item-select);
		}

		&.icon--input {
			stroke-width: 0;
		}

		&.icon--story,
		&.icon--popup,
		&.icon--video,
		&.icon--quiz,
		&.icon--minigame,
		&.icon--login,
		&.icon--high-scores {
			stroke-width: 1rem;
		}

		&.icon--score-rules {
			stroke-width: 0.5rem;
		}

		&.icon--login {
			stroke-width: 1.5rem;
		}

		&.icon--feedback {
			height: 2.6rem;
		}

		&.icon--minigame,
		&.icon--quiz {
			height: 3.5rem;
		}
	}

	&_text--icon {
		padding-left: 1.5rem;
		border-left: 1px solid white;
	}

	&_login--icon {
		padding-left: 1rem;
	}
}
