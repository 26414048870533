.help-overlay {
	.overlay {
		border-radius: var(--border-radius-popup);
		z-index: 999999;
		position: absolute;
		top: 5%;
		left: 10%;
		width: 45rem;
		height: 90vh;
		background-color: var(--color-grey-very-dark);
		display: grid;
		grid-template-rows: auto 1fr;
		overflow: hidden;
	}

	.overlay_header {
		background-color: var(--color-blue);
		display: grid;
		grid-template-columns: 1fr auto;
		align-items: center;
		height: 3rem;
		padding: 0 0.5rem;
		cursor: move;
		user-select: none;
		&--name {
			text-align: center;
		}

		.icon-container {
			.icon {
				fill: var(--color-grey-very-dark);
				stroke: var(--color-grey-very-dark);
			}
		}
	}

	.overlay_main {
		border: 1px solid var(--color-grey-very-light);
		border-top: none;
		padding: 1rem;
		@include scrollbar-wide;
		padding-bottom: 1rem;

		.help_search-bar {
			display: grid;
			grid-template-columns: auto 1fr auto;
			align-items: center;
			height: 5rem;
			margin-bottom: 1rem;
			padding: 0 1rem;

			.search-bar_input {
				background-color: var(--color-grey-very-light);
				height: 70%;
				width: 70%;
				justify-self: center;
			}

			.icon {
				fill: var(--color-grey-very-light);
				stroke: var(--color-grey-very-light);
				stroke-width: 0;
			}
		}

		.help_title {
			color: white;
			font-size: 2rem;
			font-weight: 500;
			margin-bottom: 1rem;
		}

		.help-article_card {
			display: grid;
			grid-template-columns: 5rem 1fr;
			column-gap: 0.5rem;
			border: 1px solid white;
			border-bottom: none;
			color: white;
			padding: 0.5rem;
			cursor: pointer;

			&:last-child {
				border-bottom: 1px solid white;
			}

			img {
				object-fit: cover;
				aspect-ratio: 1 / 1;
			}

			&--text {
				display: flex;
				flex-direction: column;
				height: 100%;
			}

			&--title {
				width: 35rem;
				@include text-overflow;
			}

			&--tag {
				font-size: 1.2rem;
				margin-top: auto;
			}
		}
	}
}

.help-overlay_view-article {
	.overlay_main {
		display: grid;
		grid-template-rows: auto 1fr;
		overflow: hidden; //even kijken of we de scroll in de div van het artikel zoals gemaakt in de editor zelf kunnen zetten
	}
}
