.btn {
	display: inline-block;
	font-size: 1.6rem;
	font-weight: 500;
	padding: 0.8rem 2.8rem;
	border: none;
	border-radius: 5rem;
	text-decoration: none;
	text-align: center;

	&:link,
	&:visited,
	&:focus,
	&:hover {
		cursor: pointer;
		text-decoration: none;
	}

	&--icon {
		border-radius: 50%;
		height: 4rem;
		width: 4rem;
		padding: 0;

		.icon {
			height: 1.8rem;

			&:hover {
				fill: white;
			}
		}
	}

	&--icon-mini {
		border-radius: 50%;
		height: 3rem;
		width: 3rem;
		padding: 0;

		.icon {
			height: 1.4rem;

			&:hover {
				fill: white;
				stroke: white;
			}
		}
	}

	&:disabled {
		&:hover,
		&:active {
			filter: brightness(1);
			cursor: default;
			pointer-events: none;
		}
	}

	&--orange {
		background-color: var(--color-primary);
		color: #fff;
		font-weight: 600;
		box-shadow: 0 3px 5px var(--color-grey-light);

		&:hover,
		&:active {
			filter: brightness(0.9);
		}

		&:disabled {
			background-color: var(--color-primary-light);
			box-shadow: none;
		}

		.icon {
			fill: white;
			stroke: white;

			&:hover {
				fill: white !important;
				stroke: white !important;
			}
		}
	}

	&--grey {
		background-color: var(--color-grey-very-light);
		color: var(--color-grey-very-dark);
		border: inset 1px solid var(--color-grey-very-light);
		// box-shadow: 0 1px 3px var(--color-grey-light);
		text-shadow: 0 1px 0 white;

		&:hover,
		&:active {
			filter: brightness(0.9);
		}

		.icon {
			fill: var(--color-grey-very-dark);
			stroke: var(--color-grey-very-dark);
		}

		.icon:hover {
			fill: var(--color-grey-very-dark);
			stroke: var(--color-grey-very-dark);
		}
	}

	&--dark-grey {
		background-color: var(--color-grey-medium);
		color: white;
		box-shadow: 0 3px 5px var(--color-grey-light);

		&:hover,
		&:active {
			filter: brightness(0.9);
		}

		.icon {
			fill: white;
			stroke: white;

			&:hover {
				fill: white;
				stroke: white;
			}
		}
	}

	&--yellow {
		background-color: var(--color-yellow);
		color: var(--color-grey-very-dark);
		font-weight: 600;

		&:hover,
		&:active {
			filter: brightness(0.9);
		}

		&:disabled {
			background-color: var(--color-yellow-light);
			box-shadow: none;
			color: var(--color-grey-medium);
		}
	}

	&--blue {
		background-color: var(--color-blue);
		color: var(--color-grey-very-dark);
		font-weight: 600;

		&:hover,
		&:active {
			filter: brightness(0.9);
		}

		&:disabled {
			background-color: var(--color-blue-light);
			box-shadow: none;
			color: var(--color-grey-medium);
		}
	}

	&--green {
		background-color: var(--color-btn-green);
		color: white;
		font-weight: 600;

		&:hover,
		&:active {
			filter: brightness(0.9);
		}
	}

	&--small {
		margin: 0.5rem;
		font-size: 1.2rem;
		font-weight: 400;
		padding: 0.6rem 1.2rem;
	}

	&--medium {
		padding: 0 2.4rem;
		font-size: 1.4rem;
	}
}

.form_dropdown-options {
	.btn.btn--small {
		margin: 0.5rem;
		font-size: 1.2rem;
		font-weight: 400;
		padding: 0.6rem 1.2rem;
	}
}

button {
	&:disabled {
		.icon,
		.icon:hover {
			fill: var(--color-grey-light);
			stroke: var(--color-grey-light);
			cursor: default;
		}
	}
}

.icon-container {
	display: flex;
	align-items: center;
	justify-content: center;
}

.icon {
	height: 2.4rem;
	fill: var(--color-grey-medium);
	stroke: var(--color-grey-medium);

	&:hover:not(&--disabled) {
		fill: var(--color-primary);
		stroke: var(--color-primary);
		cursor: pointer;
	}

	&--disabled {
		fill: var(--color-grey-light);
		stroke: var(--color-grey-light);
	}
}

.icon {
	&--direction-down {
		transform: rotate(90deg);
	}

	&--direction-left {
		transform: rotate(180deg);
	}

	&--direction-up {
		transform: rotate(270deg);
	}
}
