.header {
	padding: 0 0.8rem;
	display: grid;
	grid-template-columns: 20rem 1fr 20rem;
	align-items: center;

	&_left-container {
		a {
			display: flex;
			align-items: center;
		}
	}

	&_right-container {
		display: flex;
		justify-content: flex-end;
		align-items: center;
		column-gap: 5rem;

		.header_help {
			display: flex;
			column-gap: 1rem;
			align-items: center;
			justify-content: center;

			.icon {
				fill: var(--color-blue);
				stroke: var(--color-blue);
				height: 3rem;
			}
		}
	}
}

.logo_img {
	width: 3.2rem;
	margin-right: 1rem;
}

.logo_text {
	font-weight: 400;
	color: white;
	font-family: 'inter';
	font-size: 1.5rem;
}

.header {
	&_item-bar {
		background-color: var(--color-primary);
		height: 6rem;
		width: 80rem;
		justify-self: center;
		border-bottom-left-radius: 15px;
		border-bottom-right-radius: 15px;
	}

	&_help {
		color: white;
	}

	&_login {
		.icon--avatar {
			height: 4.2rem;
			fill: var(--color-blue);
			align-self: center;
		}

		.dropdownbutton-options {
			top: 4.2rem;
			right: 0;
			border: 1px solid var(--color-grey-dark);
			color: white;

			.dropdownbutton-option:hover {
				color: var(--color-primary);
			}
		}
	}
}
