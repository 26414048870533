//huidige styling in grid-vorm
.rewards {
	&_overview {
		display: grid;
		grid-template-rows: auto 1fr auto auto;
		max-height: 100%;
		font-size: 1.4rem;

		&--header {
			display: grid;
			grid-template-columns: 2fr repeat(3, 1fr) auto;
			border-bottom: 1px solid var(--color-grey-medium);
			font-weight: 500;
			padding-right: 2rem;
			font-weight: 600;
			cursor: default;

			.rewards_header--trigger,
			.rewards_header--effect,
			.rewards_header--moment {
				justify-self: center;
			}

			.rewards_header--name {
				padding-left: 2.3rem;
			}
			.rewards_header--score {
				width: 5rem;
				text-align: center;
				text-overflow: ellipsis;
				white-space: nowrap;
				overflow-x: hidden;

				&-container {
					display: flex;
					column-gap: 1rem;
				}
			}
		}
		&--content {
			height: 100%;
			@include scrollbar_effect;
			padding-right: 2rem;

			.rewards_rule-group {
				align-self: center;
				font-weight: 600;
				padding-top: 1rem;
				display: flex;

				.rule-group_name {
					padding-left: 0.5rem;
					margin-bottom: 0.2rem;
					cursor: default;
				}

				.rule-group_total {
					width: 5rem;
					text-align: center;

					&-container {
						margin-left: auto;
						display: flex;
						column-gap: 0.2rem;
					}
				}
			}

			.icon.icon--dropdown,
			.icon.icon--dropdown-right {
				height: 1.8rem;
				fill: var(--color-grey-light);
				stroke: var(--color-grey-light);
			}

			.icon.icon--dropdown-right {
				transform: rotate(270deg);
			}

			.rewards_rule-container {
				margin-bottom: 0.4rem;
			}

			.rewards_score-rule {
				padding-left: 2.3rem;
			}

			.rewards_trigger-icons,
			.rewards_effect-icons,
			.rewards_moment {
				justify-self: center;
				align-self: center;
			}
			.rewards_icons-container {
				display: flex;
				column-gap: 0.5rem;

				.icon {
					height: 2rem;
					fill: var(--color-grey-dark);
					stroke: var(--color-grey-dark);
				}

				.icon--feedback {
					stroke-width: 0;
				}
			}

			.rewards_rule-container {
				display: grid;
				grid-template-columns: 2fr repeat(3, 1fr) auto;
				align-items: center;
				cursor: pointer;
			}

			.rule-container_hover {
				display: contents;

				:hover:not(.rewards_effect-action *) {
					background-color: var(--color-grey-very-light);
				}
			}

			.rewards_score {
				width: 5rem;
				text-align: center;

				&-container {
					display: flex;
					column-gap: 0.2rem;
				}
			}
		}

		&--total {
			font-weight: 600;
			border-top: 1px solid var(--color-grey-medium);
			margin-top: 2rem;
			justify-self: end;
			width: 5rem;

			&-container {
				display: flex;
				column-gap: 1rem;
				text-align: center;
				margin-left: auto;
				padding-right: 2rem;
			}
		}

		&--add {
			justify-self: center;
			align-self: center;
		}
	}
}

.rewards_overview.rewards_overview--game {
	.rewards_overview--header,
	.rewards_rule-container {
		grid-template-columns: repeat(6, 1fr);
	}

	.rewards_header--actions,
	.rewards_effect-actions {
		justify-self: flex-start;
		align-self: center;
	}

	.rewards_effect-action {
		display: flex;
		align-items: center;
		column-gap: 0.5rem;

		.btn {
			padding: 0.4rem 0.8rem;
			font-size: 1.4rem;
		}

		.icon {
			&--show-item {
				stroke-width: 0;
			}
		}

		&-target {
			.icon {
				height: 1.8rem;
			}
		}
	}

	.rewards_effect-action-target {
		display: flex;
		align-items: center;
		column-gap: 0.5rem;
		color: var(--color-primary);
		font-weight: 500;
		font-size: 0.9em;

		.icon {
			fill: var(--color-primary);
			stroke: var(--color-primary);
		}
	}
}

//nieuwe styling in table-vorm"

.rewards_overview.nieuw {
	display: flex;
	flex-direction: column;
	height: 100%;

	.rewards_hide-counter {
		margin-top: auto;
		font-size: 1.4rem;
		font-weight: 600;
		color: var(--color-primary);
		text-decoration: underline;
		margin-right: 8rem;
		cursor: pointer;
		width: -moz-fit-content;
		width: fit-content;
	}
}
.rewards_overview {
	padding-top: 0.5rem;
	overflow: auto;
	@include scrollbar-both-wide;

	.rewards_new-window {
		display: flex;
		align-items: flex-start;
		margin-top: -0.8rem;
		padding-bottom: 0.2rem;

		.icon {
			width: 1.6rem;
			fill: var(--color-grey-dark);
			stroke: var(--color-grey-dark);

			&:hover {
				fill: var(--color-primary);
				stroke: var(--color-primary);
			}
		}
	}

	&.table {
		width: 100%;
	}

	.table {
		border: 1px solid var(--color-grey-light);
		table-layout: fixed;
		border-collapse: collapse;
		margin-bottom: 1rem;

		tr {
			height: 2.6rem;
			text-align: center;
		}

		th {
			vertical-align: middle;
			border-bottom: 1px solid var(--color-grey-light);
		}

		td {
			vertical-align: middle;
		}
	}

	.table_header {
		text-align: left;
		border-bottom: 1px solid var(--color-grey-very-light);
		font-weight: 600;
		background-color: var(--color-grey-very-light);
		text-align: center;

		&--name {
			text-align: left;
			padding-left: 2rem;
			width: 20rem;
		}

		&--trigger {
			width: 8rem;
			padding: 0 0.5rem;
		}

		&--feedback {
			width: 8rem;
			padding: 0 0.5rem;
		}

		&--effect {
			width: 12rem;
			padding: 0 0.5rem;
		}

		&--effect-action {
			width: 15rem;
			text-align: left;
		}

		&--score {
			text-overflow: ellipsis;
			overflow: hidden;
			white-space: nowrap;
			padding: 0 0.5rem;
			min-width: 5rem;
		}

		&--score-rule {
			width: 26rem;
			overflow: hidden;
			white-space: nowrap;
			padding: 0 0.5rem;
		}

		&--max {
			width: 10rem;
			border-right: 1px solid var(--color-grey-medium);
			border-left: 1px solid var(--color-grey-medium);
			background-color: var(--color-primary-light);
			padding: 0 0.5rem;
		}

		&--player {
			width: 10rem;
			text-overflow: ellipsis;
			overflow: hidden;
			white-space: nowrap;
			padding: 0 0.5rem;
		}
	}

	.table_rule-group:not(:first-child),
	.table_counter:not(:first-child) {
		height: 5rem;

		th {
			vertical-align: bottom;
		}
	}

	.table_rule-group,
	.table_counter {
		th {
			border-bottom: none;
		}

		&--name,
		&--counter {
			text-align: left;
			border-bottom: 1px solid var(--color-grey-very-light);
			font-weight: 600;

			.rule-group_name-container,
			.rule-group_counter-container,
			.counter_name-container {
				display: flex;
				cursor: pointer;

				.icon {
					height: 1.8rem;
					fill: var(--color-grey-light);
					stroke: var(--color-grey-light);
				}

				p {
					padding-left: 0.5rem;
				}
			}
		}

		&--score {
			text-align: center;
			font-weight: 600;
			cursor: default;
		}

		&--max {
			border-right: 1px solid var(--color-grey-medium);
			border-left: 1px solid var(--color-grey-medium);
			background-color: var(--color-primary-very-light);
			font-weight: 600;
			cursor: default;
		}
	}

	.table_timeline-event,
	.table_score-rule {
		&:hover {
			background-color: var(--color-grey-very-light);
			cursor: pointer;

			.effect-action_edit-container {
				display: block;
			}
		}

		.table_feedback {
			width: 8rem;
			text-overflow: ellipsis;
			overflow: hidden;
			white-space: nowrap;
		}
	}

	.table_name {
		text-align: left;
		padding-left: 2.3rem;
		text-overflow: ellipsis;
		overflow: hidden;
		white-space: nowrap;
	}

	.table_max {
		border-right: 1px solid var(--color-grey-medium);
		border-left: 1px solid var(--color-grey-medium);
		background-color: var(--color-primary-very-light);
	}

	.table_effect-action--container {
		display: flex;

		.table_effect-action {
			display: flex;
			align-items: center;
			justify-content: left;
			column-gap: 0.7rem;
			position: relative;

			.rewards_add-action {
				color: var(--color-primary);
				text-decoration: underline;
			}

			&--target {
				display: flex;
				align-items: center;
				column-gap: 0.4rem;
				color: var(--color-primary);

				.icon {
					stroke: var(--color-primary);
					fill: var(--color-primary);
					height: 1.6rem;
					stroke-width: 2rem;
				}
			}

			&--name {
				width: 12rem;
				text-overflow: ellipsis;
				overflow: hidden;
				white-space: nowrap;
				text-align: left;
			}
		}
	}

	.rewards_icons-container {
		display: flex;
		justify-content: center;
		align-items: center;

		.icon {
			height: 1.8rem;
		}

		.icon--action {
			height: 1.6rem;
		}

		.icon--counter:not(:last-child),
		.icon--feedback:not(:last-child) {
			margin-right: 0.5rem;
		}
	}

	.rewards_total--counter {
		border-top: 1px solid var(--color-grey-medium);
		font-weight: 600;
		cursor: default;
	}

	.rewards_total--max {
		border-top: 1px solid var(--color-grey-medium);
		font-weight: 600;
		border-right: 1px solid var(--color-grey-medium);
		border-left: 1px solid var(--color-grey-medium);
		background-color: var(--color-primary-very-light);
	}
}

.popup_hide-counters {
	&.popup {
		width: 30rem;
		max-height: 45rem;
	}
	.popup_main {
		.hide-counters_counter-container {
			margin-left: 10rem;
		}
	}
}

.popup.popup_view-rewards {
	min-width: 70vw;
	max-width: 80vw;
	min-height: 60vh;
	max-height: 85vh;
	row-gap: 0;
	grid-template-rows: auto 1fr;
	padding-bottom: 1rem;

	.rewards_header--name {
		column-gap: 1rem;
	}

	.rewards_header-finished {
		padding-top: 1rem;
	}

	.rewards_hide-counter {
		display: none;
	}
}

.popup_score-build {
	min-width: 28rem;
	max-width: 90vw;
	min-height: 18rem;
	max-height: 60rem;

	.rewards_overview {
		padding-top: 0 !important;
	}

	.rule-group_container {
		// &:not(:last-child) {
		// 	border-bottom: 1px solid var(--color-grey-very-light);
		// }

		.table_name {
			background-color: white;
		}

		.table_score {
			border: none;
			background-color: white;
		}
	}

	.table_rule-group {
		height: 2rem !important;
		background-color: white !important;
	}

	.table_counter--name,
	.table_rule-group--max,
	.table_rule-group--score {
		font-size: 1.6rem;
		padding: 0.5rem;
		text-decoration: underline;
	}

	.table_counter--name,
	.table_rule-group--max,
	.table_rule-group--score {
		padding-top: 2rem;
		padding-bottom: 0.1rem;
	}
}
