.widget_articles {
	.widget_header {
		display: grid;
		grid-template-columns: 1fr auto;
		padding-right: 2rem;

		&-title {
			justify-self: center;
		}

		.icon {
			fill: white;
			stroke: white;
			height: 1.8rem;
		}
	}

	.table {
		table-layout: fixed;
		.column_created,
		.column_edited {
			width: 10rem;
		}
		.column_title {
			width: 30%;
		}
		.column_tags {
			width: 20%;
		}
		.column_icons {
			width: 10%;
		}
		.column_buttons {
			width: 20%;
		}
	}

	.article_buttons {
		margin: 0.2rem 0;

		.btn {
			font-size: 1.4rem;
			margin: 0 0.25rem;
			min-width: 7rem;
			padding: 0.2rem 0.7rem;
		}
	}
}

.add-article_popup {
	.popup {
		max-width: 95vw;
		max-height: 90vh;
		width: 85vw;
		height: 95vh;
	}

	.popup_main {
		display: flex;
		flex-direction: column;
		row-gap: 2rem;

		.article_top-container {
			position: relative;

			.form_field {
				width: 50rem;
			}

			.filemanager_link {
				position: absolute;
				bottom: 0;
				right: 1rem;
				color: var(--color-primary);
				text-decoration: underline;
			}
		}

		.add-article_content {
			display: grid;
			grid-template-columns: 1fr auto;
			column-gap: 0.5rem;
			height: 100%;

			.add-article_html {
				background-color: black;
				color: white;
				font-family: Menlo, Consolas, 'Courier New', Courier, monospace;
				tab-size: 4;
			}

			.add-article_preview {
				width: 45rem;
				border: 1px solid var(--color-grey-light);
			}
		}
	}
}
