.users_popup {
	.popup {
		width: 100%;
		height: 100%;

		&_header {
			font-size: 1.8rem;
		}

		&_main {
			display: flex;
			justify-content: center;
			padding: 0 2rem;

			.content-container {
				@include scrollbar-both;
				height: fit-content;
				padding-top: 1rem;
				width: 100%;
			}

			.widget_header {
				height: 4rem;
			}

			.users_content {
				border-right: 1px solid var(--color-primary-light);
				border-bottom: 1px solid var(--color-primary-light);
				border-left: 1px solid var(--color-primary-light);

				.users_header,
				.users_user-container {
					display: grid;
					grid-template-columns: 20rem 1fr 14rem 14rem 10rem 16rem;
					column-gap: 1rem;
					padding: 0 1rem;
				}

				.users_user-container {
					padding-top: 1rem;
					padding-bottom: 1rem;
					border-bottom: 1px solid var(--color-grey-very-light);

					.users_user--name,
					.users_user--mail,
					.users_user--game {
						@include text-overflow;
					}
				}

				.users_header--active,
				.users_user--active {
					justify-self: center;
				}

				.users_header--buttons {
					width: 16rem;
				}
			}
			.users_buttons {
				justify-self: end;

				.btn {
					padding: 0.2rem 0.7rem;
					font-size: 1.4rem;
					min-width: 7rem;
					margin: 0 0.25rem;
				}
			}

			.users_add-user {
				margin-top: 1rem;
				display: flex;
				align-items: center;
				justify-content: center;
				padding-bottom: 1rem;

				.btn {
					padding: 0.5rem 1rem;
				}
			}
		}
	}
}

.edit-user_popup {
	.popup {
		width: 56rem;
		overflow-y: hidden;

		&_main {
			display: flex;
			flex-direction: column;
		}
	}

	.edit-user_content {
		display: flex;
		flex-direction: column;
		column-gap: 2rem;
		@include scrollbar;
		margin-bottom: 2rem;
	}

	.edit-user_personal {
		display: grid;
		grid-template-columns: 16rem 32rem;
		row-gap: 3rem;
		column-gap: 1rem;
		padding-left: 0.5rem;
		padding-bottom: 2rem;
		border-bottom: 1px solid var(--color-grey-very-light);

		.edit-user_first-name,
		.edit-user_phone {
			.form_field {
				width: 16rem;
			}
		}
		.edit-user_last-name,
		.edit-user_mail {
			.form_field {
				width: 32rem;
			}
		}
	}

	.edit-user_game--first,
	.edit-user_game--next {
		display: grid;
		grid-template-columns: 23rem 16rem auto;
		column-gap: 1rem;
		padding-left: 3rem;
	}

	.edit-user_game--first {
		margin-top: 2rem;
		margin-bottom: 2rem;
	}

	.edit-user_game--next {
		margin-bottom: 2rem;
	}

	.edit-user_game {
		.form_dropdown,
		.form_field {
			width: 23rem;
		}
	}

	.edit-user_role {
		.form_dropdown,
		.form_field {
			width: 16rem;
		}
	}

	.edit-user_icon--delete {
		align-self: flex-end;
		justify-self: flex-start;

		.icon-container {
			margin-bottom: 0.7rem;
		}

		.icon {
			height: 1.8rem;
		}
	}

	.edit-user_add {
		justify-self: center;
		align-self: center;
		padding-bottom: 1rem;
	}
}

.organization-settings_popup {
	.popup {
		min-width: 110rem;
		@include scrollbar-both;

		&_header {
			font-size: 1.8rem;
		}

		&_main {
			display: flex;
			justify-content: center;
			padding: 0 2rem;

			.content-container {
				@include scrollbar;
				height: fit-content;
				display: grid;
				grid-template-columns: 34rem 74rem;
				column-gap: 5rem;
				align-items: start;
				padding-top: 1rem;
			}

			.widget_header {
				height: 4rem;
			}

			.organization-settings_content {
				display: flex;
				flex-direction: column;
				row-gap: 3rem;
				padding: 2rem 3rem 3rem 3rem;
				border-right: 1px solid var(--color-primary-light);
				border-bottom: 1px solid var(--color-primary-light);
				border-left: 1px solid var(--color-primary-light);

				.organization_notes {
					.form_field {
						height: 10rem;
						max-width: 28rem;
						@include scrollbar;
					}
				}
			}

			.organization_contact-group {
				display: flex;
				flex-direction: column;
				width: fit-content;

				.organization_contact-overview {
					.contact-overview_header,
					.contact-overview_user-container {
						display: grid;
						grid-template-columns: 20rem 10rem 10rem 20rem;
						align-items: center;
					}

					.contact-overview_content {
						border-left: 1px solid var(--color-primary-light);
						border-right: 1px solid var(--color-primary-light);
						border-bottom: 1px solid var(--color-primary-light);
						max-height: 20rem;
						min-height: 15rem;
						@include scrollbar;
					}

					.contact-overview_header {
						font-weight: 600;
						padding: 0.5rem 0 0.5rem 1rem;
						border-bottom: 1px solid var(--color-grey-very-light);

						&--primary,
						&--secondary {
							justify-self: center;
						}
					}

					.contact-overview_user-container {
						padding: 0.4rem 1rem;
						border-bottom: 1px solid var(--color-grey-very-light);

						.form_radio-button {
							display: inline-block;
							justify-self: center;
						}

						.contact-overview_primary,
						.contact-overview_secondary {
							justify-self: center;
						}
					}

					.contact-overview_buttons {
						justify-self: end;

						.btn {
							padding: 0.2rem 0.7rem;
							font-size: 1.4rem;
							min-width: 7rem;
							margin: 0 0.25rem;
						}
					}
				}

				.organization_add-contact {
					align-self: center;
					margin-top: 1rem;

					.btn {
						padding: 0.5rem 1rem;
					}
				}
			}
		}
	}

	.widget_users {
		width: fit-content;
	}

	.btn-container {
		margin-right: 3rem;
	}
}

.edit-contact_popup {
	.organization_contact-details {
		position: relative;

		.contact-details_content {
			display: grid;
			grid-template-columns: 34rem 1fr;
			// display: grid;
			// grid-template-columns: 40rem 1fr;
		}

		.contact-details_left {
			display: flex;
			flex-direction: column;
			row-gap: 1.5rem;

			.form_field {
				width: 30rem;
			}

			.contact_name,
			.contact_position {
				.form_field {
					width: 22rem;
				}
			}
		}

		.phone-container {
			display: flex;
			column-gap: 2rem;

			.form_field {
				width: 14rem;
			}
		}

		.contact-details_right {
			height: fit-content;
			width: fit-content;

			.form_checkbox-group {
				padding: 0.5rem 10rem 1rem 1rem;
				background-color: #fff;
				margin: 0;

				.form_checkbox {
					accent-color: #dc761c;
				}
			}

			.checkbox_text {
				font-size: 1.6rem;
				margin-right: 5rem;
				cursor: default;
			}

			input {
				margin-left: auto;
			}
		}
	}
}

.game-settings_popup--admin {
	.popup {
		min-width: 50rem;
		max-width: 75rem;
		cursor: default;
	}

	.game-settings_content {
		margin: 2rem 2rem 4rem 2rem;
	}

	.game-settings_url {
		padding-top: 1rem;
		font-style: italic;
		word-break: break-word;

		span {
			color: var(--color-primary);
			font-weight: 600;
			font-size: 1.8rem;
		}

		.icon-container {
			display: none;
		}
	}
}
