.popup {
	@include absCenter;
	z-index: 9999;
	border: 1px solid var(--color-grey-very-dark);
	border-radius: var(--border-radius-popup);
	box-shadow: 0px 8px 24px rgba(149, 157, 165, 0.2);
	background-color: white;
	min-width: 28rem;
	max-width: 70vw;
	min-height: 18rem;
	max-height: 40rem;
	display: grid;
	grid-template-rows: auto 1fr auto;
	row-gap: 1rem;

	&_header {
		border-bottom: 1px solid var(--color-grey-very-light);
		height: 4rem;
		display: grid;
		grid-template-columns: 1fr auto;
		align-items: center;

		&-name {
			justify-self: center;
			margin-left: 3rem;
			font-weight: 500;
			color: inherit;
			cursor: default;
		}

		p {
			margin-bottom: 0;
		}

		.icon-container {
			height: 100%;
		}

		.icon--close {
			margin-right: 0.5rem;
		}
	}

	&_main {
		@include scrollbar;
		padding: 0 1rem;
	}

	&_warning--details {
		margin-left: 3rem;
		.popup_warning--detail {
			list-style: disc;
		}
	}

	.btn-container {
		justify-self: flex-end;
		height: 5rem;
		display: flex;
		align-items: center;
		justify-content: flex-end;
		column-gap: 1rem;
		margin-right: 1rem;

		.btn {
			min-width: 5rem;
		}
	}
}
