// superadmin, admin en game

.dashboard {
	@include height_page-content;
	width: fit-content;
	margin-left: 1rem;
	display: grid;
	grid-template-rows: 4rem 1fr;

	&_header {
		background-color: var(--color-grey-very-dark);
		color: #fff;
		font-size: 2.8rem;
		text-align: center;
		cursor: default;
	}
	&_widget-area {
		background-color: #fff;
		@include scrollbar-both;
		display: grid;
		grid-template-columns: repeat(5, minmax(15rem, 30rem));
		grid-auto-rows: min-content;
		gap: 2.5rem;
		padding: 2.2rem 2rem;
	}

	.widget_values {
		display: grid;
		grid-template-columns: 1fr 1fr;
		.widget_value {
			font-weight: bold;
		}
	}

	.table {
		tr:nth-child(even):not(.table_row--clickable:hover) {
			background-color: var(--color-grey-very-very-light);
		}
	}

	&_learning {
		.dashboard_header {
			position: relative;

			.dashboard_close {
				position: absolute;
				top: 0;
				right: 1rem;

				.icon {
					background-color: var(--color-grey-very-dark);
					border-radius: 50%;
					height: 4rem;
					fill: white;
					stroke: white;
				}
			}
		}
	}

	&_learning-articles {
		background-color: var(--color-grey-very-dark);
		border: 1px solid var(--color-grey-very-very-light);
		@include scrollbar-both;
		display: grid;
		grid-template-columns: repeat(5, minmax(15rem, 30rem));
		grid-auto-rows: min-content;
		gap: 2.5rem;
		padding: 2.2rem 2rem;
	}
}

.dashboard_sidebar {
	padding: 1rem;
	height: 100%;
	display: flex;
	flex-direction: column;

	&-container {
		@include height_storyline-dashboard;

		.sidebar_area {
			height: 100%;
		}
	}

	.sidebar_select-container {
		height: 16rem;

		.sidebar_select-organization,
		.sidebar_select-game {
			margin-top: 2rem;

			.form_field {
				width: 22rem;
			}

			.form_dropdown {
				margin-bottom: 2rem;
			}
		}
	}

	.sidebar_button-container {
		margin-top: 5rem;
		margin-left: 1rem;
		display: flex;
		flex-direction: column;
		justify-items: flex-start;
		row-gap: 1rem;

		.sidebar_add-organization,
		.sidebar_add-game,
		.sidebar_users,
		.sidebar_organization-settings,
		.sidebar_game-settings,
		.sidebar_learning,
		.sidebar_learning--selected {
			display: flex;
			align-items: flex-end;
			gap: 1rem;
			cursor: pointer;
		}

		.icon-container {
			height: 2.8rem;

			.icon {
				fill: var(--color-blue);
				stroke: var(--color-blue);
			}

			.icon--learning {
				stroke-width: 1;
			}
		}

		.sidebar_learning--selected {
			border-bottom: 2px solid var(--color-primary);
			margin-bottom: -2px;

			.icon-container:last-child {
				margin-left: auto;
				padding-top: 1rem;
			}

			.icon--close {
				fill: var(--color-primary);
				stroke: var(--color-primary);
			}
		}
	}

	.sidebar_back-to-admin,
	.sidebar_back-to-game {
		margin-top: auto;
		text-decoration: underline;
	}
}
