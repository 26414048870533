.learning-article {
	&_card {
		background-color: white;
		cursor: pointer;

		&--image {
			object-fit: cover;
			aspect-ratio: 16 / 9;
		}

		&--text {
			padding: 0.5rem;
			height: 10rem;
			display: flex;
			flex-direction: column;

			.article_name {
				text-align: center;
				font-weight: 500;
				flex: 0 0 80%;
			}

			.article_time {
				text-align: end;
				flex: 0 0 10%;
			}
		}
	}

	&_popup {
		.popup {
			min-width: 45rem;
		}

		.popup_header {
			background-color: var(--color-grey-very-dark);
			border-top-right-radius: var(--border-radius-popup);
			border-top-left-radius: var(--border-radius-popup);
			border: 1px solid white;
			color: var(--color-grey-very-light);

			.icon {
				fill: var(--color-grey-light);
				stroke: var(--color-grey-light);
			}
		}
	}

	&_help {
		background-color: white;
	}
}
