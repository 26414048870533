.widget_training-groups {
	display: flex;
	flex-direction: column;
	cursor: default;

	.training-groups {
		&_header {
			font-weight: 600;
			padding: 0.5rem 0 0.5rem 1rem;
			border-bottom: 1px solid var(--color-grey-very-light);
			display: grid;
			grid-template-columns: 10rem 25rem 12rem 15rem 1fr;
		}

		&_header--number,
		&_header--finished {
			justify-self: center;
		}

		&_content {
			margin-bottom: 0.5rem;
		}
	}

	.btn {
		align-self: center;
		margin-bottom: 1rem;
	}

	.training-groups_group-container {
		padding: 0.2rem 0 0.2rem 1rem;
		display: grid;
		grid-template-columns: 10rem 25rem 12rem 15rem 1fr;

		&.active {
			background-color: var(--color-grey-very-light);
		}

		.training-group_number,
		.training-group_finished {
			justify-self: center;
		}

		.training-group_finished {
			.form_checkbox {
				margin-top: 0;

				.icon--checkbox {
					height: 1.6rem;
					width: 1.6rem;
					cursor: default;
				}
			}
		}

		.training-group_button-container {
			display: flex;
			margin-left: 2rem;
		}

		.training-group_buttons {
			display: flex;

			.btn {
				padding: 0.3rem 2rem;
				font-size: 1.4rem;
				min-width: 7rem;
				margin: 0 0.25rem;

				&--running,
				&--start {
					width: 13rem;
					margin-left: 1rem;
				}

				&--running {
					background-color: #fff0c9;
					box-shadow: inset 0 0 0 2px var(--color-yellow);
					color: var(--color-grey-medium);
					font-style: italic;
					cursor: default;
				}

				&--eye {
					margin-left: 0.2rem;

					p {
						padding-left: 0.2rem;
						color: var(--color-grey-medium);
						font-style: italic;
						font-size: 1.2rem;
						padding-top: 0.1rem;
					}

					.icon {
						fill: var(--color-grey-medium);
						stroke: var(--color-grey-medium);
						height: 2.2rem;
					}
				}
			}
		}
	}
}

.popup_training-group {
	&.popup {
		width: 100rem;
		display: grid;
		grid-template-rows: auto 1fr;
	}

	.popup_main {
		@include scrollbar-both;
		display: grid;
		grid-template-columns: 45rem 1fr;

		.training-group_popup-left {
			padding-right: 2rem;
			display: flex;
			flex-direction: column;

			.training-group_form-container {
				display: flex;
				column-gap: 2rem;

				.training-group_number {
					.form_field {
						width: 5rem;
						border: 1px solid var(--color-grey-light);
						background-color: var(--color-grey-very-light);
					}
				}
			}

			.training-group_trainer,
			.training-group_container,
			.training-group_notes {
				margin-top: 3rem;
			}

			.training-group_date {
				.form_field {
					width: 15rem;
				}
			}

			.training-group_container {
				display: flex;
				column-gap: 2rem;
			}

			.training-group_finished {
				align-self: flex-end;
				margin-right: 5rem;
				justify-self: end;
				display: flex;
				align-items: flex-end;
				margin-left: auto;
				column-gap: 0.5rem;
				margin-bottom: 0.2rem;
			}

			.training-group_notes {
				.training-group_notes-field {
					width: 100%;
					display: block;
					@include scrollbar;
					border: 1px solid var(--color-grey-very-light);
					border-bottom: 1px solid var(--color-primary);
					padding: 0.5rem;
					height: 10rem;
				}
			}

			.btn--delete {
				background-color: red;
				color: white;
				padding: 0.3rem 0;
				width: 22rem;
				justify-self: flex-end;
				align-self: center;
				margin-top: 3rem;
			}
		}

		.training-group_popup-right {
			border-left: 1px solid var(--color-grey-very-light);
			padding-left: 2rem;
			display: flex;
			flex-direction: column;

			.training-group_url-container {
				display: flex;
				flex-direction: column;

				.training-group_url {
					display: flex;
					column-gap: 1rem;
					background-color: #ffe6d9;
					padding: 0.3rem;
					border-radius: 2px;

					.icon {
						fill: var(--color-primary);
						stroke: var(--color-primary);
					}

					span {
						word-break: break-all;
					}
				}
			}

			.training-group_no-results {
				margin-top: 4rem;

				p {
					height: 25rem;
					background-color: var(--color-grey-very-light);
					display: flex;
					align-items: center;
					justify-content: center;
				}
			}

			.training-group_results-container {
				margin-top: 4rem;

				.results_header {
					display: flex;
					margin-bottom: 1rem;
					justify-content: flex-end;
					align-items: flex-end;

					label {
						font-weight: 800;
						font-size: 1.7rem;
					}

					.btn_score-build {
						margin-left: auto;
						margin-right: 3rem;
						align-self: start;
						justify-self: end;
						color: var(--color-primary);
						font-weight: 500;
						box-shadow: 0 0 0 2px var(--color-grey-light);
						border-radius: 5rem;
						width: 21rem;
						padding: 0.3rem 0;
						display: flex;
						-moz-column-gap: 0.5rem;
						column-gap: 0.5rem;
						align-items: center;
						justify-content: center;

						p {
							padding-top: 0.3rem;
						}

						.icon {
							fill: var(--color-primary);
							stroke: var(--color-primary);
						}
					}

					.btn_delete-scores {
						stroke-width: 0;
						align-self: center;
						margin-right: 0.5rem;

						.icon {
							height: 1.8rem;
							width: 1.8rem;
						}
					}
				}

				.training-group_scores-container {
					display: flex;
					flex-direction: column;

					.training-group_players {
						@include scrollbar;
						height: 28rem;
					}

					.training-group_player {
						display: grid;
						grid-template-columns: 1fr auto auto;
						align-items: center;
						justify-content: center;
						column-gap: 1rem;
						margin-top: 1rem;

						.player_score {
							border: 1px solid var(--color-grey-light);
							padding: 0.6rem 1rem;
							width: 10rem;
							background-color: var(--color-grey-very-light);
							text-align: center;
							font-weight: 500;
						}

						.player_view {
							margin-right: 2rem;

							.btn {
								padding: 0.3rem 1.6rem;
								margin-bottom: 0;
							}
						}
					}
				}
			}

			.btn-container {
				margin-top: auto;
				margin-bottom: 0;
				height: fit-content;
			}
		}
	}

	.finished-1 {
		vertical-align: super;
		font-size: 1.2rem;
		color: var(--color-primary);
	}

	.training-group_finished-1 {
		margin-top: 2rem;
		font-style: italic;
		font-size: 1.4rem;
		padding: 0 0 1rem 0;
		align-self: flex-start;
	}

	.btn-container {
		margin-left: auto;
		height: fit-content;

		.btn {
			align-self: end;
			justify-self: end;
		}
	}
}

.popup_delete-results {
	ul li {
		list-style-type: disc;
		margin-left: 3rem;
	}

	p {
		margin-bottom: 0.5rem;
	}

	.popup_main {
		display: block;
	}
}

.popup_delete-training-group {
	&.popup {
		// width: 30rem;
		text-align: center;
	}
}
