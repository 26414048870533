// Game-editor: Open de action details door op de actie bij een item te klikken

.action-details {
	width: 30rem;
	@include max-height_edit-action;
	@include scrollbar;
	z-index: 9000;
	scrollbar-gutter: stable;
	position: absolute;
	bottom: 0;
	right: 31rem;
	border: 1px solid var(--color-grey-light);
	background-color: white;
	display: flex;
	flex-direction: column;
	padding-bottom: 1rem;

	&_header {
		border-bottom: 1px solid var(--color-grey-very-light);
		height: 3.5rem;
		display: grid;
		grid-template-columns: 1fr auto;
		align-items: center;
		cursor: default;

		&-name {
			justify-self: center;
			font-weight: 500;
			color: inherit;
			padding-left: 3rem;
		}

		.icon--close {
			margin-right: 0.5rem;
		}
	}

	&_settings {
		background-color: white;

		&_edit-container {
			display: grid;
			grid-template-columns: auto auto;
			align-items: center;
			justify-content: flex-end;
			height: 4rem;

			.icon--copy,
			.icon--delete {
				padding-right: 1rem;
			}
		}

		.settings_tag,
		.settings_timeline {
			padding-left: 1rem;
			margin-bottom: 10rem;

			.form_dropdown {
				width: 28rem;
			}
		}

		.settings_url {
			padding-left: 1rem;
		}

		.form_checkbox {
			padding-left: 1rem;
		}
	}

	.select-form_container {
		margin-top: 0;
	}

	.edit-name {
		padding-left: 1rem;
	}
}

.effect {
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	margin: 1rem 1rem 0 1rem;

	&_header {
		color: var(--color-primary);
		font-weight: 500;
		margin: 2rem 0 1rem 0;
		text-align: center;
		cursor: default;
	}

	&_settings {
		position: relative;
		align-self: flex-start;
		margin: 1rem;

		.form_field {
			margin-left: 0;
			margin-bottom: 1rem;
			column-gap: 1rem;
		}

		.form_dropdown-option {
			display: flex;
			column-gap: 1rem;
			margin: 0.5rem 0;
		}

		.form_label {
			margin-right: 1rem;
			margin-left: 0;

			&-left {
				margin-right: 0;
			}
		}

		.form_label-left {
			width: 26rem;
		}
	}

	&_icon--edit {
		position: absolute;
		top: 0;
		right: 0;

		.icon {
			height: 2rem;
		}
	}

	&_direction {
		.icon-arrow--left {
			rotate: (-180deg);
		}

		.icon-arrow--up {
			rotate: (270deg);
		}

		.icon-arrow--down {
			rotate: (90deg);
		}
	}
}
