.dashboard {
	&_header {
		position: relative;
	}

	.btn_edit-game {
		position: absolute;
		top: -1rem;
		right: 1rem;
		font-size: 1.6rem;
		padding: 1rem 3.2rem;
		padding: 0.8rem 2.4rem;

		&--blue {
			padding: 0.8rem 2rem;
			margin: 0 0.2rem;
		}
	}
}

.game-settings_popup--game {
	.popup {
		width: 64vw;

		&_header {
			font-size: 1.8rem;
		}

		&_main {
			display: flex;
			justify-content: center;
			padding: 0 2rem;

			.content-container {
				@include scrollbar;
				height: fit-content;
				padding-top: 1rem;
				display: grid;
				grid-template-columns: 1fr 1fr;
				column-gap: 2rem;
			}

			.widget_header {
				height: 4rem;
			}

			.game-settings_data {
				width: 56rem;
			}

			.game-settings_menu {
				width: 36rem;
			}

			.game-settings_content {
				display: flex;
				flex-direction: column;
				padding: 2rem 3rem 3rem 3rem;
				border-right: 1px solid var(--color-primary-light);
				border-bottom: 1px solid var(--color-primary-light);
				border-left: 1px solid var(--color-primary-light);

				.game_slug,
				.game_password {
					margin-top: 3rem;
				}

				.form_checkbox {
					margin-top: 0.5rem;
					margin-left: 19rem;
				}

				.game-settings_url--title {
					margin-top: 3rem;
				}

				.game-settings_url {
					margin-top: 1rem;
					font-style: italic;
					word-break: break-word;
					display: flex;
					column-gap: 0.8rem;

					span {
						color: var(--color-primary);
						font-weight: 600;
					}
				}

				.game-settings_show_menu {
					margin-left: 0;
				}
			}
		}
	}

	.btn-container {
		margin-right: 3rem;
	}
}
